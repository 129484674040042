/*
Template Name: Velonic Dashboard build with Bootstrap v3.3.6
Template Version: 2.1
Author: CoderThemes
Email: coderthemes@gmail.com
Date: Jan 2015
Last Update: Nov 2015
*/

/* TABLE OF CONTENTS
    1) General
        1.1 Loader
    2) Left Panel
        2.1 Logo
        2.2 Navigation
        2.3 Sub-menu
    3) Top Header
    4) Content Area
    5) Footer
    6) Panel-Tab-Accordions
    7) Portlets
    8) Widgets
    9) Chat / Todo
   10) Calendar
   11) Forms
       11.1 Form wizard
   12) Datatable
   13) Chart
   14) Mail
   15) Maps
   16) Profile
   17) Timeline
   18) Pages (Log-in,Register Etc....)

   ======= Version 1.1 ======
   19) Flot-chart / Rickshaw


   ====== Version 2.0 =====
   - Responsive

*/
@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700);

/******** 1. General ********/
body {
    color: #232324;
    background-color: #f5f5f5;
    font-family: "Roboto", sans-serif;
    padding: 0px !important;
    margin: 0px !important;
    font-size: 14px;
}
html {
    overflow-x: hidden;
    position: relative;
    min-height: 100%;
}

/* 1.1 Loader */
.pace {
    -webkit-pointer-events: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.pace-inactive {
    display: none;
}

.pace .pace-progress {
    background: #d7171f;
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 3px;
}

.pace .pace-progress-inner {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #d7171f, 0 0 5px #d7171f;
    opacity: 1;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -moz-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    -o-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}

.pace .pace-activity {
    display: block;
    position: fixed;
    z-index: 2000;
    top: 80px;
    right: 30px;
    width: 30px;
    height: 30px;
    border: solid 2px transparent;
    border-top-color: #d7171f;
    border-left-color: #d7171f;
    border-radius: 50px;
    -webkit-animation: pace-spinner 400ms linear infinite;
    -moz-animation: pace-spinner 400ms linear infinite;
    -ms-animation: pace-spinner 400ms linear infinite;
    -o-animation: pace-spinner 400ms linear infinite;
    animation: pace-spinner 400ms linear infinite;
}

@-webkit-keyframes pace-spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-moz-keyframes pace-spinner {
    0% {
        -moz-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-o-keyframes pace-spinner {
    0% {
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-ms-keyframes pace-spinner {
    0% {
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes pace-spinner {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/******** 2. Left Panel (aside navigation) ********/
aside.left-panel {
    background-color: #2f2f30;
    width: 250px;
    position: fixed;
    height: 100%;
    top: 0px;
    left: 0px;
}
aside.left-panel.alten-aside {
    border-right: 5px solid #008bd2;
    background-color: #043962;
}

aside.left-panel.collapsed {
    overflow: visible !important;
    position: absolute;
    bottom: 0px;
}

aside.left-panel.lg {
    width: 250px;
}

aside.left-panel.lg + .content {
    margin-left: 250px;
}

/***** 2.1 LOGO *****/
.logo {
    padding-top: 20px;
    text-align: center;
}

.logo-expanded {
    font-size: 20px;
    letter-spacing: 0.05em;
    color: #fff !important;
    font-weight: 700;
    text-transform: uppercase;
}
.logo-expanded i {
    font-size: 28px;
    vertical-align: middle;
    color: #d7171f;
    display: block;
}

/******** 2.2 Navigation *******/

.icon_nav > ul.icon_drop_ul > li > a {
    display: block;
    padding: 12px 25px;
    color: #b4b6bd;
    font-size: 13px;
    text-decoration: none;
    transition: all 0.4s;
    -moz-transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    font-weight: 400;
    outline: none !important;
}
.alten-menu {
    color: white;
    font-family: "Raleway", sans-serif;
    font-size: 50px;
    margin: 15px;
}

.thealtenhubtitle {
    font-family: Roboto;
    margin-left: 15px;
    color: white;
    margin-bottom: 15px;
}
ul.list-unstyled.alten-menu-list > li {
    padding-bottom: 15px;
}

ul.list-unstyled.alten-menu-list > li > a {
    color: white;
    font-size: 15px;
    letter-spacing: 1px;
}

ul.list-unstyled.alten-menu-list > li > a,
aside.alten-aside > a {
    text-decoration: none;
}

ul.list-unstyled.alten-menu-list > li > a:hover,
aside.alten-aside > a:hover {
    color: white;
    text-decoration: none;
}

.alten-menu-list {
    margin-left: 15px;
}

.icon_drop_ul {
    display: none;
    position: relative;
    width: 250px;
    background-color: #232324;
    top: -6px;
    margin: 0;
}

.icon_drop_ul.active {
    display: block;
}

.icon_nav > ul > li > div > a > span.badge {
    position: absolute;
    z-index: 100;
    right: 5px;
    top: 1px;
}

aside.left-panel.collapsed .navigation > ul > li > a .badge {
    display: none;
}

.icon_nav > ul.active > li > a:hover:not(.empty),
.icon_nav > ul.active > li > a.active {
    color: #fff;
}

.icon_nav > ul.active > li > a.empty {
    cursor: default;
}

.icon_nav > ul > li:hover > div > a {
    color: #fff;
    background: #232324;
}

.collapsed .navigation ul li a i {
    float: none;
}

.timesheet-badge {
    background-color: #bf2c2c;
    font-weight: 700;
    float: none;
}

.icon_nav ul li ul li a {
    padding: 8px 25px 8px 55px;
    color: #9e9e9e;
    text-decoration: none;
    white-space: nowrap;
    transition: all 0.2s;
    -moz-transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    font-size: 14px;
    border-left: 0px solid #9e9e9e;
    text-align: left;
    display: block;
    outline: none !important;
}

/******************** 3.Top Header ****************/

header {
    background-color: #fff;
    padding-top: 0;
    padding-bottom: 0;
}

.navbar-toggle {
    margin: 8px 20px 8px 0px;
    display: block;
    padding-left: 0px;
}

.navbar-toggle .icon-bar {
    background-color: #b3b3be;
}

.app-search {
    position: relative;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 15px;
}
.app-search a {
    position: absolute;
    top: 8px;
    right: 15px;
    color: #c4c4cd;
}

.app-search .form-control,
.app-search .form-control:focus {
    border: none;
    font-size: 13px;
    color: #444444;
    padding-left: 20px;
    background: #edf1f2;
    box-shadow: none;
    border-radius: 34px;
    margin-top: 1px;
}

.app-search .form-control::-moz-placeholder {
    color: #c4c4cd;
}
.top-nav .navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
    background: #f5f5f5;
}

.dropdown .extended {
    width: 280px;
    padding: 0;
    margin-left: -1px;
    z-index: 5;
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    border: none;
    /* max-height: 330px; */
    margin-top: 4px;
}

.dropdown .extended li {
    border-bottom: 1px solid #f0f0f0;
    line-height: 20px;
}

.dropdown .extended li a {
    padding: 10px 20px !important;
}

.dropdown .extended i {
    float: left;
    width: 38px;
    margin-right: 10px;
    text-align: center;
    margin-top: 5px;
}

.dropdown .extended p {
    font-weight: 600;
    background-color: #f5f5f5;
    padding: 10px 15px 11px 15px;
    margin-bottom: 0px;
}

.dropdown .pro-menu {
    width: 200px;
}

.Leftmenu-trigger {
    cursor: pointer;
    font-size: 16px;
    line-height: 64px;
    padding: 0px 15px 0px 7px;
}

.badge.up {
    position: relative;
    top: -12px;
    padding: 3px 6px;
    margin-left: -13px;
}

.top-nav ul.top-menu > li {
    margin-left: 10px;
}

.top-menu li {
    height: 100%;
    display: inline-block;
    position: relative;
    float: left;
    line-height: 50px;
    padding: 0px;
}

.top-menu li > a {
    color: #979898;
    text-align: center;
    padding: 22px 15px;
    font-size: 16px;
}

#server-type {
    padding: 10px;
    margin: 0 10px;
    vertical-align: sub;
    font-size: large;
    font-weight: bold;
    background-color: #f6af11;
}

#release-version {
    background-color: #f5f5f5;
    font-size: 14px;
    color: #979898;
}

#release-version span {
    float: right;
    padding: 5px 20px;
}

.top-menu li > a:hover,
.top-menu li > a:focus,
.top-menu li > a:active {
    background-color: #f5f5f5;
}

.top-menu .dropdown-menu li {
    width: 100%;
    text-align: left !important;
}

.top-menu .dropdown-menu li a {
    font-size: 14px;
    text-align: left;
    padding: 5px 15px;
}

.username {
    font-size: 14px;
    vertical-align: middle;
    margin-left: 3px;
}

.header-noti li a {
    font-size: 14px;
}

/***************** 4.Content Area (right) ****************/

.content {
    margin-left: 250px;
}

.content > .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
}

.wraper {
    padding-top: 20px;
    min-height: 550px;
    padding-bottom: 70px;
}
.page-title {
    padding: 0px 0px 20px;
}
.page-title-float-buttons {
    display: flex;
    justify-content: space-between;
}
.page-title h3 {
    margin: 0px;
    font-size: 20px;
}

.collapsed + .content .footer {
    left: 75px;
}

/***************** 5.Footer ****************/
.footer {
    color: #58666e;
    position: absolute;
    left: 250px;
    right: 0px;
    bottom: 0px;
    padding: 20px 30px;
    background-color: #ffffff;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

/***************** 6.Panel-Tab-Accordions ****************/
.nav.nav-tabs + .tab-content,
.tabs-vertical-env .tab-content {
    background: #fff;
    padding: 30px;
    margin-bottom: 30px;
}

.nav.nav-tabs > li.active > a,
.tabs-vertical-env .nav.tabs-vertical li.active > a {
    border: 0;
    background-color: #fff;
    border-top: 2px solid #d8d6d6;
}

.nav.nav-tabs > li > a {
    border: none;
    background-color: #eee;
    margin-right: 5px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    border-radius: 0;
    border-top: 2px solid #eee;
}

.nav.nav-tabs > li:last-of-type a {
    margin-right: 0px;
}

.nav.nav-tabs {
    border-bottom: 0;
}

.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:focus,
.tabs-vertical-env .nav.tabs-vertical li.active > a {
    border: none;
    border-top: 2px solid #d8d6d6;
}

.tabs-vertical-env {
    margin-bottom: 30px;
}

.tabs-vertical-env .nav.tabs-vertical {
    min-width: 120px;
    width: 150px;
}

.tabs-vertical-env .nav.tabs-vertical,
.tabs-vertical-env .tab-content {
    display: table-cell;
    vertical-align: top;
}

.panel-group .panel .panel-heading a[data-toggle="collapse"].collapsed:before,
.panel-group .panel .panel-heading .accordion-toggle.collapsed:before {
    content: "\f067";
}

.panel-group .panel .panel-heading a[data-toggle="collapse"]:before,
.panel-group .panel .panel-heading .accordion-toggle:before {
    float: right;
    display: block;
    content: "\f068";
    font-family: "Font Awesome 5 Free";
    font-size: 14px;
    width: 25px;
    text-align: right;
}

.panel-group .panel .panel-heading a[data-toggle="collapse"],
.panel-group .panel .panel-heading .accordion-toggle {
    display: block;
}

.tabs-vertical-env .nav.tabs-vertical li > a {
    background-color: #eee;
    margin-bottom: 5px;
    text-align: center;
    white-space: nowrap;
}

.panel-group .panel .panel-heading + .panel-collapse .panel-body {
    border-top: 1px solid #dddddd;
    margin-top: 15px;
}

.panel-group.panel-group-joined .panel + .panel,
.panel-group-joined .panel-group .panel + .panel {
    margin-top: 0;
    border-top: 1px solid #ddd;
}

.table > thead > tr > td.middle-align,
.table > tbody > tr > td.middle-align,
.table > tfood > tr > td.middle-align,
.table > thead > tr > th.middle-align,
.table > tbody > tr > th.middle-align,
.table > tfood > tr > th.middle-align {
    vertical-align: middle;
}
/* Modal-use */
.modal-content .nav.nav-tabs + .tab-content,
.tabs-vertical-env .tab-content,
.modal-content .panel-group {
    margin-bottom: 0px;
}

.modal-content .panel {
    border-top: none;
}

/***************** 7.Portlets ****************/
.portlet {
    margin-bottom: 20px;
    background: #fff;
    transition: all 0.4s;
    -moz-transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.portlet .portlet-heading {
    padding: 20px 30px;
    line-height: 38px;
    min-height: 39px;
    border-radius: 3px;
    color: #fff;
}

.portlet .portlet-heading .portlet-title {
    float: left;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 0;
}

.portlet .portlet-heading .portlet-widgets {
    position: relative;
    text-align: right;
    float: right;
    padding-left: 15px;
    display: inline-block;
    font-size: 15px;
    line-height: 0px;
}

.portlet .portlet-body {
    background: #fff;
    padding: 15px;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.portlet .portlet-heading .portlet-widgets .divider {
    margin: 0 5px;
}

.portlet .portlet-heading.bg-purple a,
.portlet .portlet-heading.bg-info a,
.portlet .portlet-heading.bg-success a,
.portlet .portlet-heading.bg-primary a,
.portlet .portlet-heading.bg-danger a,
.portlet .portlet-heading.bg-warning a,
.portlet .portlet-heading.bg-inverse a,
.portlet .portlet-heading.bg-pink a {
    color: #fff;
}

.portlet .portlet-heading a {
    color: #999;
}

.panel-disabled {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 0;
    bottom: 15px;
    background: rgba(243, 242, 241, 0.5);
}

.loader-1 {
    -webkit-animation: loaderAnimate 1000ms linear infinite;
    -moz-animation: loaderAnimate 1000ms linear infinite;
    -o-animation: loaderAnimate 1000ms linear infinite;
    animation: loaderAnimate 1000ms linear infinite;
    clip: rect(0, 30px, 30px, 15px);
    height: 30px;
    width: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -15px;
    margin-top: -15px;
}

lesshat-selector {
    -lh-property: 0;
}

@-webkit-keyframes loaderAnimate {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(220deg);
    }
}

@-moz-keyframes loaderAnimate {
    0% {
        -moz-transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(220deg);
    }
}

@-o-keyframes loaderAnimate {
    0% {
        -o-transform: rotate(0deg);
    }

    100% {
        -o-transform: rotate(220deg);
    }
}

@keyframes loaderAnimate {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(220deg);
        -moz-transform: rotate(220deg);
        -ms-transform: rotate(220deg);
        transform: rotate(220deg);
    }
}

lesshat-selector {
    -lh-property: 0;
}

@-webkit-keyframes loaderAnimate2 {
    0% {
        box-shadow: inset #555 0 0 0 8px;
        -webkit-transform: rotate(-140deg);
    }

    50% {
        box-shadow: inset #555 0 0 0 2px;
    }

    100% {
        box-shadow: inset #555 0 0 0 8px;
        -webkit-transform: rotate(140deg);
    }
}

@-moz-keyframes loaderAnimate2 {
    0% {
        box-shadow: inset #555 0 0 0 8px;
        -moz-transform: rotate(-140deg);
    }

    50% {
        box-shadow: inset #555 0 0 0 2px;
    }

    100% {
        box-shadow: inset #555 0 0 0 8px;
        -moz-transform: rotate(140deg);
    }
}

@-o-keyframes loaderAnimate2 {
    0% {
        box-shadow: inset #555 0 0 0 8px;
        -o-transform: rotate(-140deg);
    }

    50% {
        box-shadow: inset #555 0 0 0 2px;
    }

    100% {
        box-shadow: inset #555 0 0 0 8px;
        -o-transform: rotate(140deg);
    }
}

@keyframes loaderAnimate2 {
    0% {
        box-shadow: inset #555 0 0 0 8px;
        -webkit-transform: rotate(-140deg);
        -moz-transform: rotate(-140deg);
        -ms-transform: rotate(-140deg);
        transform: rotate(-140deg);
    }

    50% {
        box-shadow: inset #555 0 0 0 2px;
    }

    100% {
        box-shadow: inset #555 0 0 0 8px;
        -webkit-transform: rotate(140deg);
        -moz-transform: rotate(140deg);
        -ms-transform: rotate(140deg);
        transform: rotate(140deg);
    }
}

.loader-1:after {
    -webkit-animation: loaderAnimate2 1000ms ease-in-out infinite;
    -moz-animation: loaderAnimate2 1000ms ease-in-out infinite;
    -o-animation: loaderAnimate2 1000ms ease-in-out infinite;
    animation: loaderAnimate2 1000ms ease-in-out infinite;
    clip: rect(0, 30px, 30px, 15px);
    content: "";
    border-radius: 50%;
    height: 30px;
    width: 30px;
    position: absolute;
}

@keyframes loaderAnimate2 {
    0% {
        box-shadow: inset #2f353f 0 0 0 17px;
        transform: rotate(-140deg);
    }

    50% {
        box-shadow: inset #2f353f 0 0 0 2px;
    }

    100% {
        box-shadow: inset #2f353f 0 0 0 17px;
        transform: rotate(140deg);
    }
}

/***************** 8.Widget  ****************/
.widget-panel {
    padding: 40px 20px;
    border-radius: 4px;
    position: relative;
    margin-bottom: 20px;
}

.widget-style-1 i {
    position: absolute;
    font-size: 6rem;
    right: 0;
    bottom: 1rem;
    color: #edf0f0;
}
.widget-style-1 h2 {
    font-weight: 600;
    font-size: 32px;
}

.widget-style-1:hover i {
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
}
.widget-style-2 {
    padding-left: 30px;
}
.widget-style-2 i {
    font-size: 48px;
    float: right;
    padding: 35px 35px 29px;
    margin-top: -40px;
    margin-right: -20px;
    background: rgba(33, 33, 33, 0.018);
}
.widget-style-2 h2 {
    font-weight: 600;
    font-size: 28px;
}

.profile-widget {
    background: #ffffff;
    margin-bottom: 20px;
}

.bg-profile {
    height: 100px;
}

.profile-widget .img-thumbnail {
    margin-top: -42px;
    border: none;
}

.widget-list {
    padding: 10px;
    margin-top: 20px;
    margin-left: 0px;
    padding-bottom: 30px;
}

.widget-list span {
    display: block;
    font-weight: bold;
    font-size: 18px;
}

.tile-stats {
    padding: 20px 0px 0px 0px;
    position: relative;
    min-height: 128px;
    overflow: hidden;
    margin-bottom: 20px;
}

.tile-stats .status {
    margin-top: 0px;
    padding: 0px 20px 10px 20px;
}

.status-ab {
    position: absolute;
    right: 0;
}

.mini-stat {
    background: #fff;
    padding: 20px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin-bottom: 20px;
}

.mini-stat-icon {
    width: 60px;
    height: 60px;
    display: inline-block;
    line-height: 60px;
    text-align: center;
    font-size: 30px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    float: left;
    margin-right: 10px;
    color: #fff;
}

.mini-stat-info {
    font-size: 12px;
    padding-top: 2px;
}

.mini-stat-info span {
    display: block;
    font-size: 24px;
    font-weight: 600;
}

/************* 9.Chat / Todo **********/
#todo-message {
    font-size: 16px;
}

.conversation-list {
    list-style: none;
    padding: 0px 20px;
    max-height: 350px;
}

.conversation-list li {
    margin-bottom: 24px;
}

.conversation-list .chat-avatar {
    width: 40px;
    display: inline-block;
    text-align: center;
    float: left;
}

.conversation-list .chat-avatar img {
    width: 100%;
    border-radius: 100%;
}

.conversation-list .chat-avatar i {
    font-size: 12px;
    font-style: normal;
}

.conversation-list .ctext-wrap i {
    display: block;
    font-style: normal;
    font-weight: bold;
    position: relative;
    font-size: 12px;
    color: #1a2942;
}

.conversation-list .conversation-text {
    display: inline-block;
    font-size: 12px;
    float: left;
    margin-left: 12px;
    width: 70%;
}

.conversation-list .ctext-wrap {
    padding: 10px;
    background: #f5f5f5;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    position: relative;
    display: inline-block;
}

.conversation-list .ctext-wrap p {
    margin: 0px;
    padding-top: 3px;
}

.conversation-list .ctext-wrap:after {
    right: 100%;
    top: 20%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(213, 242, 239, 0);
    border-right-color: #f5f5f5;
    border-width: 5px;
    margin-top: -5px;
}

.conversation-list .odd .chat-avatar {
    float: right !important;
}

.conversation-list .odd .conversation-text {
    width: 70% !important;
    margin-right: 12px;
    text-align: right;
    float: right !important;
}

.conversation-list .odd .ctext-wrap {
    background: #e8e9ec !important;
}

.conversation-list .odd .ctext-wrap i {
    color: #acacac;
}

.conversation-list .odd .ctext-wrap:after {
    left: 100% !important;
    top: 20% !important;
    border-color: rgba(238, 238, 242, 0) !important;
    border-left-color: #e8e9ec !important;
}

.chat-send {
    padding-left: 0px;
    padding-right: 30px;
}

.chat-send button {
    width: 100%;
}

.chat-inputbar {
    padding-left: 30px;
}
/****ToDo List****/
.todoapp {
    padding: 10px 30px 20px 30px !important;
}
.todo-list li {
    border: 0px;
    margin: 0px;
    border-radius: 0px;
    border-bottom: 1px solid #eee;
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.todo-list li:last-of-type {
    border-bottom: none;
}

.todo-send {
    padding-left: 0px;
}
/************* 10.Calendar **********/
.calendar {
    margin-bottom: 0px;
    float: left;
}

.fc-toolbar {
    margin-bottom: 5px;
}

.fc-toolbar h2 {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 30px;
}

.fc-day {
    background: #fff;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
    z-index: 0;
}

.fc-widget-header,
.fc-widget-content {
    border: 1px solid #d5d5d5;
}

.fc th.fc-widget-header {
    background: #ddd;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 20px;
    padding: 10px 0px;
}

.fc-button {
    border: 1px solid #d5d5d5;
    background: #fff;
    color: #555;
    text-transform: capitalize;
}

.fc-text-arrow,
.fc-text-arrow {
    font-family: arial;
    font-size: 16px;
}

.fc-state-hover {
    background: #f5f5f5;
}

.fc-state-highlight,
.fc-cell-overlay {
    background: #f0f0f0;
}

.fc-unthemed .fc-today {
    background: #fff;
}

.fc-event {
    font-size: 13px;
    padding: 5px 5px;
    text-align: center;
    margin-bottom: 5px;
    background: #1a2942;
    border: none;
    border-radius: 2px;
    cursor: move;
}

#external-events .fc-event {
    background: #d7171f;
}

.new-event-form {
    margin-bottom: 20px;
}

.fc-ltr .fc-basic-view .fc-day-number {
    padding: 5px 6px;
}

/************* 11.Forms **********/
/* Form-validation */
.error {
    color: #ff0000;
}
/* Datapicker */
.datepicker {
    border: 1px solid #ddd;
}
/* Multiple Select */
.search-input {
    margin-bottom: 10px;
}

.ms-selectable {
    outline: none !important;
    box-shadow: none;
}

.ms-container .ms-list.ms-focus {
    box-shadow: none;
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
    background-color: #d7171f;
}
/* spinner */
.spinner-buttons.btn-group-vertical .btn {
    height: 17px;
    margin: 0;
    padding-left: 6px;
    padding-right: 6px;
    text-align: center;
    width: 22px;
}

.spinner-buttons.btn-group-vertical .btn i {
    margin-top: -3px;
    line-height: 10px;
}

.spinner-buttons.btn-group-vertical .btn:first-child {
    border-radius: 0 4px 0 0 !important;
    -webkit-border-radius: 0 4px 0 0 !important;
}

.spinner-buttons.btn-group-vertical .btn:last-child {
    border-radius: 0 0 4px !important;
    -webkit-border-radius: 0 0 4px !important;
}

/* summernote */
.note-editor .note-toolbar {
    background-color: #f3f3f3;
    border-bottom: 1px solid #dfdfdf;
    margin: 0;
}

.note-editor {
    position: relative;
    border: 1px solid #dfdfdf;
}

.note-editor .note-statusbar {
    background-color: #fff;
}

.note-editor .note-statusbar .note-resizebar {
    height: 15px;
    border-top: 1px solid #dfdfdf;
    padding-top: 3px;
}

.note-popover .popover .popover-content,
.note-toolbar {
    padding: 5px 0 10px 5px;
}

/* Code Editer */
.code-edit-wrap {
    padding-top: 0px !important;
    margin-left: -30px;
    margin-bottom: -20px;
    margin-right: -30px;
}

.cm-s-ambiance .CodeMirror-linenumber {
    color: #999;
}
/*------------------
    11.1 form wizard
------------------*/

.wizard > .content {
    background: #fff;
    min-height: 240px;
}

.wizard > .content > .body {
    padding: 0px;
}

.wizard > .content > .body input {
    border: 1px solid #eeeeee;
}

.wizard > .content > .body ul > li {
    display: block;
}

.wizard > .steps .number {
    display: inline-block;
    line-height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.wizard > .steps .disabled a,
.wizard > .steps .disabled a:hover,
.wizard > .steps .disabled a:active {
    background: #f2f2f2;
    color: #323232;
    cursor: default;
}

.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active,
.wizard > .content {
    border-radius: 2px;
}

.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active {
    background: #d7171f;
}

.wizard > .steps .current a .number,
.wizard > .steps .current a:hover .number,
.wizard > .steps .current a:active .number {
    background: transparent;
    color: #ffffff;
}

.wizard > .steps .done a,
.wizard > .steps .done a:hover,
.wizard > .steps .done a:active {
    background: #1a2942;
}

.wizard > .content > .body label.error {
    margin-left: 0;
    color: #e55957;
}

.wizard > .actions {
    margin-bottom: 30px;
}

.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active {
    background: #d7171f;
    color: #fff;
    border-radius: 2px;
}

.wizard > .actions .disabled a,
.wizard > .actions .disabled a:hover,
.wizard > .actions .disabled a:active {
    background: #e2e2e2;
    color: #323232;
}

.wizard > .content > .body label {
    display: inline-block;
    margin-top: 10px;
}

.wizard > .content > .body ul > li {
    display: block;
    line-height: 30px;
}
/* Datapiker */
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
    box-shadow: none;
    background-color: #1a2942 !important;
    background-image: none;
}

/************* 12.Datatable **********/
#datatable td {
    font-weight: normal;
}

div.dataTables_paginate ul.pagination {
    margin-top: 30px;
}

div.dataTables_info {
    padding-top: 38px;
}
/* Responsive Table */
table.focus-on tbody tr.focused th,
table.focus-on tbody tr.focused td {
    background-color: #d7171f;
    color: #ffffff;
}
.table-rep-plugin tbody th {
    font-weight: normal;
    font-size: 14px;
}
/* Editable Table */
.modal-block {
    background: transparent;
    padding: 0;
    text-align: left;
    max-width: 600px;
    margin: 40px auto;
    position: relative;
}
#datatable-editable .actions a {
    padding: 5px;
}
#datatable-editable .form-control {
    background-color: #ffffff;
    width: 100%;
}
#datatable-editable .fa-trash-o,
#datatable-editable .fa-times {
    color: #ef5350;
}
#datatable-editable .fa-pencil {
    color: #29b6f6;
}
#datatable-editable .fa-save {
    color: #33b86c;
}

/************* 13.Chart **********/
/* Sparkline-Chart */
.jqstooltip {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
/** EASY PIE CHART **/
.chart {
    position: relative;
    display: inline-block;
    width: 110px;
    height: 110px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.chart.chart-widget-pie {
    margin-top: 5px;
    margin-bottom: 5px;
}

.chart canvas {
    position: absolute;
    top: 0;
    left: 0;
}

.percent {
    display: inline-block;
    line-height: 110px;
    z-index: 2;
}

.percent:after {
    content: "%";
    margin-left: 0.1em;
    font-size: 0.8em;
}

/************* 14.Mail **********/

.mails .mail-rateing {
    color: #9d9ea5;
    width: 1%;
}

.mails .mail-select {
    width: 1%;
}

.mails .label {
    margin-right: 10px;
}

.mail-list i {
    width: 20px;
    text-align: center;
}

/************* 15.Maps **********/
.gmaps,
.gmaps-panaroma {
    height: 300px;
    background: #1a2942;
    border-radius: 3px;
}

.gmaps-overlay {
    display: block;
    text-align: center;
    color: #fff;
    font-size: 16px;
    line-height: 40px;
    background: #1a2942;
    border-radius: 4px;
    padding: 10px 20px;
}

.gmaps-overlay_arrow {
    left: 50%;
    margin-left: -16px;
    width: 0;
    height: 0;
    position: absolute;
}

.gmaps-overlay_arrow.above {
    bottom: -15px;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 16px solid #1a2942;
}

.gmaps-overlay_arrow.below {
    top: -15px;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 16px solid #1a2942;
}

.vmap-space {
    width: 100%;
    height: 350px;
}

.jvectormap-zoomin,
.jvectormap-zoomout,
.jqvmap-zoomin,
.jqvmap-zoomout {
    border: 1px solid #eaeaea;
    background: #fff;
    border-radius: 0;
    color: #545454;
    width: 28px;
    height: 28px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
}

.jvectormap-zoomin:hover,
.jvectormap-zoomout:hover,
.jqvmap-zoomin:hover,
.jqvmap-zoomout:hover {
    background: #eaeaea;
}

.jvectormap-zoomin,
.jqvmap-zoomin {
    position: absolute;
    top: 0px;
}

.jvectormap-zoomout,
.jqvmap-zoomout {
    position: absolute;
    top: 27px;
}

/************* 16.Profile **********/
.uprofile-image img {
    height: 100px;
    width: 100px;
}

ul.social-icons {
    padding: 0;
    margin-left: 0;
}

ul.social-icons li {
    list-style: none;
    display: inline-block;
    margin-left: 5px;
}

ul.social-icons li:first-child {
    margin-left: 0px;
}

ul.social-icons li a {
    display: inline-block;
    margin: 0;
    width: 36px;
    height: 36px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    text-decoration: none;
    text-align: center;
    transition: all 0.17s ease-in-out;
    -moz-transition: all 0.17s ease-in-out;
    -webkit-transition: all 0.17s ease-in-out;
    -o-transition: all 0.17s ease-in-out;
}

ul.social-icons li a.facebook {
    background: #3e5ea2;
}

ul.social-icons li a.twitter {
    background: #2eb0e3;
}

ul.social-icons li a.github {
    background: #fff;
}

ul.social-icons li a.pinterest {
    background: #f43a2c;
}

ul.social-icons li a.google {
    background: #da5f2e;
}

ul.social-icons li a.youtube {
    background: #df2826;
}

ul.social-icons li a.linkedin {
    background: #107cb7;
}

ul.social-icons li a:hover {
    opacity: 0.8;
}

ul.social-icons li a i {
    color: #fff;
    font-size: 15px;
    line-height: 36px;
    transition: all 0.17s ease-in-out;
    -moz-transition: all 0.17s ease-in-out;
    -webkit-transition: all 0.17s ease-in-out;
    -o-transition: all 0.17s ease-in-out;
}

/************* 17.Timeline **********/
/* Timeline */
.timeline {
    position: relative;
    padding: 20px 0 20px;
    list-style: none;
    text-align: right;
}

.timeline:before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 3px;
    margin-left: -1.5px;
    background-color: #999;
}

.timeline > li {
    position: relative;
    margin-bottom: 20px;
}

.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}

.timeline > li:after {
    clear: both;
}

.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}

.timeline > li:after {
    clear: both;
}

.timeline > li > .timeline-panel {
    float: left;
    position: relative;
    width: 46%;
    padding: 20px;
    background: #fbfbfb;
    border: 1px solid #e7e7e7;
}

.timeline > li > .timeline-panel:before {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 26px;
    right: -15px;
    border-top: 15px solid transparent;
    border-right: 0 solid #ccc;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #ccc;
}

.timeline > li > .timeline-panel:after {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 27px;
    right: -14px;
    border-top: 14px solid transparent;
    border-right: 0 solid #fbfbfb;
    border-bottom: 14px solid transparent;
    border-left: 14px solid #fbfbfb;
}

.timeline > li > .timeline-badge {
    z-index: 100;
    position: absolute;
    top: 16px;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    border-radius: 50% 50% 50% 50%;
    text-align: center;
    font-size: 1.4em;
    line-height: 50px;
    color: #fff;
    background-color: #999999;
}

.timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    text-align: left;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
}

.timeline-badge.primary {
    background-color: #d7171f !important;
}

.timeline-badge.success {
    background-color: #33b86c !important;
}

.timeline-badge.warning {
    background-color: #ebc142 !important;
}

.timeline-badge.danger {
    background-color: #cb2a2a !important;
}

.timeline-badge.info {
    background-color: #1ca8dd !important;
}

.timeline-title {
    margin-top: 0;
    color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
    margin-bottom: 0;
}

.timeline-body > p + p {
    margin-top: 5px;
}

/* Timeline-2 */
.timeline-2 {
    position: relative;
    border-left: 2px solid #14082d;
}

.time-item {
    position: relative;
    padding-bottom: 1px;
    border-color: #dee5e7;
}

.time-item:before,
.time-item-item:after {
    display: table;
    content: " ";
}

.timeline-2 .time-item:after,
.time-item:after {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 5px;
    width: 14px;
    height: 14px;
    margin-left: -8px;
    background-color: #fff;
    border-color: #14082d;
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    content: "";
}

.item-info {
    margin-left: 15px;
    margin-bottom: 15px;
}

.item-info p {
    margin-bottom: 10px !important;
}

.profile-desk h1 {
    color: #49586e;
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 5px 0;
    text-transform: uppercase;
}

.profile-desk .designation {
    color: #49586e;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 30px;
    display: inline-block;
}

.profile-desk p {
    line-height: 25px;
    margin-bottom: 40px;
}

.p-follow-btn {
    background: #eff0f4;
    color: #a4a4a4;
}

ul.p-social-link {
    list-style-type: none;
}

ul.p-social-link li {
    display: inline-block;
}

ul.p-social-link li a {
    background: #eff0f4;
    color: #bfbfc1;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    display: inline-block;
    text-align: center;
}

/*********** 18. Conatct *********/
.media-main a.pull-left {
    width: 100px;
}

.media-main .info {
    overflow: hidden;
    color: #000;
}

.media-main .info h4 {
    padding-top: 10px;
    margin-bottom: 5px;
}
.social-links li a {
    background: #eff0f4;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    color: #7a7676;
}

/*********** 19. Pages / Carousel *********/
/* Log-in */
.wrapper-page {
    width: 380px;
    margin: 140px auto 140px;
}

/* Lock-screen */
.user-thumb img {
    margin: 0px auto;
}

/* Error-pages */
.ex-page-content h1 {
    font-size: 150px;
    line-height: 150px;
    font-weight: 700;
    color: #252932;
    text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px,
        rgba(61, 61, 61, 0.3) 3px 3px;
}

/* Sweet-alert **/
.sweet-alert p {
    font-size: 14px;
}

/* Carousel */
.velonic-carousel {
    padding: 20px 30px;
}

.slider-bg {
    background-size: cover;
    position: relative;
}

.slider-overlay {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    height: 100%;
    width: 100%;
    margin: -20px -30px;
}

.owl-buttons {
    display: none;
}

/*Profile-v2*/
.bg-picture {
    position: relative;
    min-height: 300px;
    margin: -20px -25px 0px -20px;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
    background-size: cover;
}
.bg-picture > .bg-picture-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0d…0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0,
        rgba(0, 0, 0, 0.4) 100%
    );
    background-image: -o-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0,
        rgba(0, 0, 0, 0.4) 100%
    );
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(255, 255, 255, 0)),
        to(rgba(0, 0, 0, 0.4))
    );
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0,
        rgba(0, 0, 0, 0.4) 100%
    );
}
.bg-picture > .meta.bottom {
    bottom: 10px;
}
.bg-picture > .meta {
    position: absolute;
    left: 0;
    right: 0;
}
.box-layout {
    display: table !important;
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
}

#flotTip {
    padding: 4px 8px;
    background-color: #000;
    z-index: 100;
    color: #fff;
    opacity: 0.7;
    font-size: 12px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.progress-animated {
    -webkit-transition: 5s all;
    -webkit-animation-duration: 5s;
    -webkit-animation-name: animationProgress;
    transition: 5s all;
    animation-duration: 5s;
    animation-name: animationProgress;
}
@-webkit-keyframes animationProgress {
    from {
        width: 0;
    }
}
@keyframes animationProgress {
    from {
        width: 0;
    }
}
.profile-tabs {
    background-color: #eeeeee;
    padding-bottom: 1px;
}
.nav.nav-tabs.profile-tabs > li > a {
    border: none;
    margin-right: 0px;
    padding: 15px 30px;
    font-size: 16px;
}
.nav.nav-tabs.profile-tabs > li > a i {
    margin-right: 10px;
}

.org_team_edit_icon {
    display: inline-block !important;
    padding: 0px 0px 0px 0px !important;
    position: absolute !important;
    transform: translate(-26px, 13px) !important;
}

/* Flot Chart */
.flot-chart {
    width: 100%;
    text-align: center;
    margin: 0 auto;
}

#legend {
    background: white;
    position: absolute;
    top: 0;
    right: 15px;
}
#legend .line {
    color: #333;
}

/* =============== RESPONSIVE =================== */

/*** Aside Collapsed ***/
@media (min-width: 769px) {
    aside.left-panel.collapsed {
        width: 75px;
        text-align: center;
    }

    aside.left-panel.collapsed + .content {
        margin-left: 75px;
    }

    aside.left-panel.collapsed .user .user-login,
    aside.left-panel.collapsed span.nav-label {
        display: none;
    }

    aside.left-panel.collapsed .navigation > ul > li > a {
        padding: 15px;
    }
    aside.left-panel.collapsed .navigation > ul > li > a:hover {
        background-color: #272c35;
        border-left: 3px solid #d7171f;
    }

    aside.left-panel.collapsed i.fa {
        font-size: 22px;
    }

    aside.left-panel.collapsed .navigation > ul > li.has-submenu:after {
        display: none;
    }
}

/*** Aside Collapsed Sub Menu ***/
@media (min-width: 769px) {
    aside.left-panel.collapsed .navigation ul li ul {
        position: absolute;
        z-index: 3;
        left: 100%;
        top: 0px;
        background-color: #232324;
        box-shadow: none;
        padding: 10px 0px;
        min-width: 200px;
    }

    aside.left-panel.collapsed .navigation > ul > li:hover > ul {
        display: block !important;
    }

    aside.left-panel.collapsed
        .navigation
        > ul
        > li:hover
        > ul
        aside.left-panel.collapsed
        .navigation
        ul
        li
        ul
        li
        a {
        border: 0px;
        color: #232324;
        padding: 8px 25px 8px 40px;
    }

    aside.left-panel.collapsed .navigation ul li ul li a:hover {
        color: #fff;
    }
}
@media (max-width: 768px) {
    aside.left-panel.collapsed {
        width: 250px;
        left: 0px;
        overflow: hidden !important;
    }

    aside.left-panel.collapsed + .content {
        margin-left: 0px;
        transform: translate3d(250px, 0px, 0px);
        -ms-transform: translate3d(250px, 0px, 0px);
        -webkit-transform: translate3d(250px, 0px, 0px);
        -moz-transition: translate3d(250px, 0px, 0px);
        -o-transition: translate3d(250px, 0px, 0px);
    }

    aside.left-panel {
        left: 100%;
    }

    .footer {
        left: 0;
    }

    section.content {
        margin-left: 0px;
    }

    .content > .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }

    .page-header h1 {
        margin-top: 0px;
    }

    button.navbar-toggle.pull-left {
        display: block !important;
    }
}

@media (max-width: 450px) {
    .username {
        display: none;
    }
    .dropdown .extended i {
        display: none;
    }
}
