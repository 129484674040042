.bold {
    font-weight: 500;
}

a,
.qrstyle {
    color: #d7171f;
}

.qsrstyle {
    color: #00a86b;
}

.panel-body li a {
    word-wrap: break-word;
    white-space: normal;
    text-align: left;
}

.char-count {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #adaeb0;
}

.red-text {
    color: red;
}

.errorlist {
    color: red;
    font-weight: bold;
}

.hub-danger {
    color: #ff4136;
}

.panel .panel-body p {
    margin-bottom: 8px;
}

.panel-body.panel-overflow {
    overflow-x: auto;
}

.bg-light {
    background-color: #f0f0f0;
}

.panel-new {
    border-top: 5px solid #edc239;
}

.panel-padded {
    margin: 8px 6px;
}

.btn-new {
    color: #302f31;
    background-color: #edc239;
    border-color: #c6a331;
}

#wiki-edit-sidebar .panel-heading {
    padding: 0;
    background-color: inherit;
}

.wiki-article div.toc,
.wiki-article div.article-list {
    max-width: 340px;
    clear: left;
    display: inline-block;
    /* Fix height problem when next to floating elements */
}

.wiki-article div.toc .nav-header,
.wiki-article div.article-list .nav-header {
    padding: 3px 10px;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
}

.wiki-article div.toc ul,
.wiki-article div.article-list ul {
    padding-left: 0;
    list-style: none;
}

.wiki-article div.toc > ul,
.wiki-article div.article-list > ul {
    border-radius: 5px;
    background-color: #f6f6f6;
    padding-top: 5px;
}

.wiki-article div.toc ul li ul li,
.wiki-article div.article-list ul li ul li {
    padding-left: 10px;
}

.wiki-article div.toc ul li a,
.wiki-article div.article-list ul li a {
    padding: 5px 10px;
    display: block;
    border-bottom: 1px solid #ddd;
}

.wiki-article div.toc ul li:last-child,
.wiki-article div.article-list ul li:last-child {
    margin-bottom: 7px;
}

.wiki-article div.toc .toctitle {
    display: block;
    padding: 3px 20px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #777777;
    white-space: nowrap;
}

ul.top-right-menu {
    margin-bottom: 0;
}

@font-face {
    font-family: "ModeNine";
    src: url(../../static/fonts/MODENINE.TTF),
        url(../../static/fonts/MODENINE.eot),
        url("../../static/fonts/MODENINE.eot?#iefix")
            format("embedded-opentype");
}

@font-face {
    font-family: "DolceVitaLight";
    src: url(../../static/fonts/DolceVitaLight.ttf),
        url(../../static/fonts/DolceVitaLight.eot),
        url("../../static/fonts/DolceVitaLight.eot?#iefix")
            format("embedded-opentype");
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    letter-spacing: 2px;
    font-weight: bold;
}

.profile-img {
    object-fit: cover;
    object-position: 0 0;
}

.background-center {
    background-position: 0% 18%;
}

.clickable {
    cursor: pointer;
}

.table-clickable {
    cursor: pointer;
}

.logo-expanded {
    text-transform: none;
}

.logo-expanded:hover {
    text-decoration: none;
}

@media (max-width: 769px) {
    aside.left-panel h3.thehubtitle-short {
        display: none;
    }

    aside.left-panel div.logo-short {
        display: none;
    }
}

@media (min-width: 769px) {
    aside.left-panel.collapsed div.logo {
        display: none;
    }

    aside.left-panel div.logo-short {
        display: none;
    }

    aside.left-panel.collapsed div.logo-short {
        display: block;
    }

    aside.left-panel.collapsed h3.thehubtitle {
        display: none;
    }

    aside.left-panel h3.thehubtitle-short {
        display: none;
    }

    aside.left-panel.collapsed h3.thehubtitle-short {
        display: block;
    }
}

.thehubtitle-short {
    text-align: center;
}

.thehubtitle {
    margin-left: 15px;
}

.thehubtitle a:hover,
.thehubtitle a:focus {
    text-decoration: none;
}

.thehubtitle a,
.thehubtitle-short a {
    color: #ffffff;
    font-family: Roboto;
}

.qrname {
    text-align: center;
    font-family: DolceVitaLight;
    color: #ffffff;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 5px;
}

@media screen and (max-width: 1080px) {
    .top-bar-links div {
        display: none;
    }
}

.top-bar-links div {
    float: left;
    margin-top: 8px;
    margin-bottom: 8px;
    height: 35px;
    margin-right: 12px;
}

.qricon {
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    height: 35px;
    width: 35px;
    line-height: 32px;
    vertical-align: middle;
    font-size: 35px;
}

.li_tab .qricon {
    height: 25px;
    width: 25px;
    line-height: 22px;
}

.inherit-color {
    color: inherit;
}

.inherit-color:hover {
    color: inherit;
    text-decoration: none;
}

.small-pad {
    padding-left: 30px;
}

.bordericon {
    border: 1px solid lightgrey;
    border-radius: 5px;
}

.selectedicon {
    background-color: lightgrey;
}

.sealicon {
    background-image: url(../../static/images/seal.png);
}

.questionicon {
    background-image: url(../../static/images/question.png);
}

.staricon {
    background-image: url(../../static/images/star.png);
}

.llicon {
    background-image: url(../../static/images/lessons_learnt_logo.png);
}

.hearticon {
    background-image: url(../../static/images/heart.png);
}

.bmwicon {
    background-image: url(../../static/images/bmw.png);
}

.ozicon {
    background-image: url(../../static/images/oz.png);
}

.threepoint0icon {
    background-image: url(../../static/images/3point0.png);
}

.msstreamicon {
    background-image: url(../../static/images/microsoft-stream.svg);
}

.skillsmatrixicon {
    background-image: url(../../static/images/skillsmatrix.png);
}

.rosetteicon {
    background-image: url(../../static/images/rosette.png);
}

.dequiterewardingicon {
    background-image: url(../../static/images/dequiterewarding.png);
}

.quiterewardingicon {
    background-image: url(../../static/images/quiterewarding.png);
}

.thequickresponseicon {
    background-image: url(../../static/images/tickets.png);
}

.dropboxicon {
    background-image: url(../../static/images/dropbox.png);
}

.zimbraicon {
    background-image: url(../../static/images/zimbra-new.png);
}

.linkedinicon {
    background-image: url(../../static/images/linkedin.png);
}

.slackicon {
    background-image: url(../../static/images/slack-logo.png);
}

.spicon {
    background-image: url(../../static/images/splogo.png);
}

.dficon {
    background-image: url(../../static/images/dflogo.png);
}

.googledocsicon {
    background-image: url(../../static/images/googledocs.png);
}

.qrsiteicon {
    background-image: url(../../static/images/qrsquare.png);
}

.expensesicon {
    background-image: url(../../static/images/expenses.png);
}

.timesheeticon {
    background-image: url(../../static/images/timesheet.png);
}

.barclaycardicon {
    background-image: url(../../static/images/barclaycard.png);
}

.accommodationicon {
    background-image: url(../../static/images/accommodation.png);
}

.travelicon {
    background-image: url(../../static/images/travel.png);
}

.meetingfacilityicon {
    background-image: url(../../static/images/meetingfacility.png);
}

.trainingicon {
    background-image: url(../../static/images/training.png);
}

.purchaseicon {
    background-image: url(../../static/images/purchase.png);
}

.quoteicon {
    background-image: url(../../static/images/quote.png);
}

.poicon {
    background-image: url(../../static/images/po.png);
}

.invoiceicon {
    background-image: url(../../static/images/invoice.png);
}

.pisicon {
    background-image: url(../../static/images/pis.png);
}

.casestudyicon {
    background-image: url(../../static/images/casestudy.png);
}

.notesicon {
    background-image: url(../../static/images/notes.png);
}

.onepagericon {
    background-image: url(../../static/images/onepagerlogo.png);
}

.healthassuredicon {
    background-image: url(../../static/images/health_assured.jpg);
}

.guidanceresourcesicon {
    background-image: url(../../static/images/guidance_resources.jpg);
}

.dr_engine {
    background-image: url("../../static/images/drimages/engine_medium.png");
}

.focusarea1icon {
    background-image: url("../../static/images/focus_areas/Purpose and Objectives.png");
}

.focusarea2icon {
    background-image: url("../../static/images/focus_areas/Stakeholders, Communication and Governance.png");
}

.focusarea3icon {
    background-image: url("../../static/images/focus_areas/Project Management, Resourcing and Compliance.png");
}

.focusarea4icon {
    background-image: url("../../static/images/focus_areas/Data and Documents.png");
}

.focusarea5icon {
    background-image: url("../../static/images/focus_areas/Processes, Systems and Tools.png");
}

.focusarea6icon {
    background-image: url("../../static/images/focus_areas/Metrics and KPIs.png");
}

.focusarea7icon {
    background-image: url("../../static/images/focus_areas/Business Development.png");
}

.focusarea8icon {
    background-image: url("../../static/images/focus_areas/Continuous Improvement.png");
}

.cignaicon {
    background-image: url(../../static/images/cigna.png);
}

.deltadentalicon {
    background-image: url(../../static/images/deltadental.png);
}

.hsaicon {
    background-image: url(../../static/images/hsa.png);
}

.adpicon {
    background-image: url(../../static/images/adp.png);
}

.vspicon {
    background-image: url(../../static/images/vsp.png);
}

.sq {
    float: left;
    position: relative;
    width: 18%;
    padding: 0 0 18% 0; /* = width for a 1:1 aspect ratio */
    margin: 1%;
    overflow: hidden;
    white-space: normal;
    word-wrap: break-word;
    border: solid 1px transparent;
}

.sq_full_workflow {
    width: 6.5%;
    margin: 0.5%;
    padding: 0 0 6.5% 0;
}

.sq_expand {
    height: auto;
    padding: 0px;
}

.sq_content {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 10% 10%;
    text-align: center;
    font-size: 14px;
}

.sq_full_workflow .sq_content,
.sq_expand div {
    font-size: 10px;
}

@media screen and (max-width: 970px) {
    .sq_content {
        font-size: 10px;
    }

    .sq_full_workflow .sq_content,
    .sq_expand div {
        font-size: 8px;
    }
}

@media screen and (max-width: 750px) {
    .sq_content {
        font-size: 8px;
    }

    .sq_full_workflow .sq_content,
    .sq_expand div {
        font-size: 6px;
    }
}

.sq_table {
    display: table;
    height: 100%;
    width: 100%;
}

.sq_table-cell {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    width: 100%;
}

.score0,
.score1,
.score2,
.score3,
.score4,
.score5 {
    text-align: center !important;
    vertical-align: middle !important;
    color: black;
}

.score1 {
    background: rgb(255, 0, 0);
}

.score2 {
    background: rgb(255, 192, 0);
}

.score3 {
    background: rgb(0, 255, 0);
}

.score4 {
    background: rgb(144, 144, 144);
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjOTA5MDkwIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2JjYmNiYyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(
        -45deg,
        rgb(144, 144, 144) 0%,
        rgb(188, 188, 188) 100%
    );
    background: -webkit-gradient(
        linear,
        left top,
        right bottom,
        color-stop(0%, rgb(144, 144, 144)),
        color-stop(100%, rgb(188, 188, 188))
    );
    background: -webkit-linear-gradient(
        -45deg,
        rgb(144, 144, 144) 0%,
        rgb(188, 188, 188) 100%
    );
    background: -o-linear-gradient(
        -45deg,
        rgb(144, 144, 144) 0%,
        rgb(188, 188, 188) 100%
    );
    background: -ms-linear-gradient(
        -45deg,
        rgb(144, 144, 144) 0%,
        rgb(188, 188, 188) 100%
    );
    background: linear-gradient(
        135deg,
        rgb(144, 144, 144) 0%,
        rgb(188, 188, 188) 100%
    );
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#909090', endColorstr='#bcbcbc',GradientType=1 )";
}

.score5 {
    background: rgb(166, 166, 0);
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjElIiBzdG9wLWNvbG9yPSIjYTZhNjAwIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZlZmU0MCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(
        -45deg,
        rgb(166, 166, 0) 1%,
        rgb(254, 254, 64) 100%
    );
    background: -webkit-gradient(
        linear,
        left top,
        right bottom,
        color-stop(1%, rgb(166, 166, 0)),
        color-stop(100%, rgb(254, 254, 64))
    );
    background: -webkit-linear-gradient(
        -45deg,
        rgb(166, 166, 0) 1%,
        rgb(254, 254, 64) 100%
    );
    background: -o-linear-gradient(
        -45deg,
        rgb(166, 166, 0) 1%,
        rgb(254, 254, 64) 100%
    );
    background: -ms-linear-gradient(
        -45deg,
        rgb(166, 166, 0) 1%,
        rgb(254, 254, 64) 100%
    );
    background: linear-gradient(
        135deg,
        rgb(166, 166, 0) 1%,
        rgb(254, 254, 64) 100%
    );
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6a600', endColorstr='#fefe40',GradientType=1 )";
}

.userimage {
    width: 100px;
    margin: 5px;
}

.biguserimage {
    width: 100%;
    margin: 5px;
}

.skillsflowchart legend {
    font-size: 16px;
    margin-bottom: 10px;
}

#financecomplexobjectitems input[type="number"] {
    width: 60px;
}

#financecomplexobjectitems select[id$="employee"] {
    width: 150px;
}

#financecomplexobjectitems select[id$="time_unit"] {
    width: 80px;
}

#financecomplexobjectitems input[id$="description"] {
    width: 300px;
}

#financecomplexobjectitems input[id$="total"] {
    width: 80px;
}

#financecomplexobjectitems select[id$="revenue_project"] {
    width: 100px;
}

.financecomplexobjectitemheading {
    float: left;
    text-align: center;
    margin-left: 1px;
    margin-right: 1px;
}

#piqheatmap td,
#piqheatmap th {
    padding: 0 !important;
    text-align: center;
}

.badtogoodgradient {
    color: black;
    width: 100%;
    background: -webkit-linear-gradient(
        left,
        red,
        yellow,
        lawngreen
    ); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(
        right,
        red,
        yellow,
        lawngreen
    ); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(
        right,
        red,
        yellow,
        lawngreen
    ); /* For Firefox 3.6 to 15 */
    background: linear-gradient(
        to right,
        red,
        yellow,
        lawngreen
    ); /* Standard syntax */
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr='#FF0000', endColorstr='#7CFC00')"; /*For IE7-8-9*/
}

#opsrequestform ul,
.opsreqstable ul {
    list-style-type: none;
    padding: 0;
}

#userpermissionsmatrix ul {
    padding: 0;
    text-align: center;
    list-style-type: none;
}

#userpermissionsmatrix th {
    text-align: center;
}

#userpermissionsmatrix label,
#userpermissionsmatrix input {
    margin: 0;
}

.spreqstable th,
.opsreqstable th {
    background-color: lightgrey;
    color: black;
    border-color: black !important;
}

.spreqstable td,
.spreqstable,
.opsreqstable td,
.opsreqstable {
    border-color: black !important;
}

.modal-backdrop {
    z-index: 0;
}

.dropdown-backdrop {
    position: static;
}

.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}

.bootstrap_tabular_fix {
    padding: 0;
    display: inline-block;
    height: 100%;
    width: 100%;
}

.bootstrap_tabular_fix input,
.bootstrap_tabular_fix select {
    margin: 0;
    height: 100% !important;
    display: inline-block;
    width: 100%;
    padding: 0;
}

.bootstrap_tabular_fix .checkbox {
    margin-top: 2px;
    margin-bottom: 0px;
    vertical-align: middle;
}

.bootstrap_tabular_fix .checkbox input {
    height: 15px !important;
}

.clear-disabled input:disabled {
    background-color: #ffffff !important;
    border-color: #ffffff !important;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px 6px;
    border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
    display: block;
}

.dropdown-submenu > a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #cccccc;
    margin-top: 5px;
    margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
    border-left-color: #ffffff;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}

#workingdaybox,
#workingdaybox td,
#workingdaybox th {
    text-align: center;
    margin: 0;
    font-size: 8px;
    padding: 0;
}

.big-wrapper-page {
    width: 380px;
    margin: 0px auto 0px;
}

button.navbar-toggle.pull-left {
    display: none;
}

.task,
.taskText {
    cursor: pointer;
}

.selector .selector-available h2,
.selector .selector-chosen h2,
.selector .selector-available p,
.selector .selector-chosen p {
    margin: 0;
}

.hiddenlink {
    color: #58666e;
}

.hiddenlink:hover {
    color: #58666e;
}

.DR2018 {
    background-image: url(../../static/images/qr2018background.png);
    width: 100%;
    height: 100%;
    min-height: 600px;
    font-family: DolceVitaLight;
    overflow: auto;
}

.DR2018Notification {
    background-color: #d2090f !important;
    color: white;
    padding: 2px 15px;
    margin: 0;
    font-size: 30px;
}

.DR2018 .btn {
    background-color: black !important;
    color: white;
}

.DR2018 input {
    font-size: 30px;
    text-align: center;
}

.qr2018icon {
    background-image: url(../../static/images/lock.png);
}

.panel-qr {
    border-color: #fff;
}

.panel-qr > .panel-heading {
    color: #fff;
    background-color: #d7171f;
    border-color: #d7171f;
}

.panel-qr > .panel-heading > h3 {
    color: #fff;
}

$login-backgrounds: (
    1: "../../static/images/login/abstract-2-seats.jpg",
    2: "../../static/images/login/abstract-4-wave.jpg",
    3: "../../static/images/login/abstract-6-cross-section.jpg",
    4: "../../static/images/login/industry-automotive-2-road-2.jpg",
    5: "../../static/images/login/industry-automotive-7-fusebox.jpg",
    6: "../../static/images/login/industry-electronics-1.jpg",
    7: "../../static/images/login/industry-manufacturing-1-robots.jpg",
    8: "../../static/images/login/industry-manufacturing-4-drilling.png",
);

@each $i, $bg in $login-backgrounds {
    .login-background-#{$i} {
        background-image: url($bg);
        background-attachment: fixed;
        background-size: cover;
    }
}

.pink {
    color: #ff69b4;
}

.backpink {
    background: #ff69b4;
    color: black;
}

/* gray background in weekends, white text color */
.vis-time-axis .vis-grid.vis-saturday,
.vis-time-axis .vis-grid.vis-sunday {
    background: #d3d3d3;
}

.ass-confirmed.vis-item {
    background-color: #0059b3;
    border: 0;
}

.ass-unconfirmed.vis-item {
    background-color: #ffbf00;
    border: 0;
}

.ass-tbc-confirmed.vis-item {
    background-color: #ff0000;
    border: 0;
}

.ass-tbc-unconfirmed.vis-item {
    background-color: #ff9999;
    border: 0;
}

.boardkpiimage {
    width: 100%;
}

blockquote.boardkpicomment {
    font-size: 12px;
}

.nav.nav-tabs.profile-tabs.hr-tabs > li > a {
    padding: 10px 20px;
}

.bdtotal {
    background-color: #d8d8d8;
}

.bdtarget {
    background-color: #ffffff;
}

#personal-bd-table .bdtotal {
    background-color: #ffffff;
}

#personal-bd-table .bdtarget {
    background-color: #efefef;
    font-weight: bold;
    border-top: #afafaf 3px solid !important;
}

.bdthisweek {
    background-color: #ffff00 !important;
}

#personal-bd-table table tr:first-of-type th:not(:last-of-type),
#personal-bd-table table tr th:nth-of-type(2n - 1):not(:last-of-type),
#personal-bd-table table tr th:nth-of-type(6),
#personal-bd-table table tr th:nth-of-type(1),
#personal-bd-table table tr td:nth-of-type(2),
#personal-bd-table table tr td:nth-of-type(4),
#personal-bd-table table tr td:nth-of-type(5) {
    border-right: #afafaf 3px solid !important;
}

#personal-bd-table table tr:first-of-type th:not(:last-of-type) {
    border-top: 1px solid #eee;
}

#personal-bd-table table {
    border: none !important;
}

#personal-bd-table table thead tr:first-of-type th:last-of-type {
    border-top: none !important;
    border-right: none !important;
}

/* Fix for Datatables with Scrollbar */
.dataTables_scrollBody thead,
.DTFC_LeftBodyLiner thead {
    visibility: hidden;
}

#ItemPanel .linedescription {
    min-width: 250px;
}

#ItemPanel .lineprojectcode,
#ItemPanel .linetimedata {
    padding: 2px;
}

#ItemPanel .form-group {
    margin-bottom: 0;
}

#ItemPanel .linetotal {
    padding: 2px;
}

.progress-bar-black {
    background-color: #000000;
}

.videoicon {
    cursor: pointer;
}

.posubtable {
    font-size: 10px;
}

.table-content-full-width {
    width: 100%;
}

.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
    position: static;
    margin-left: 0;
}

.badge-danger {
    background-color: #bf2c2c;
    font-weight: 700;
    float: none;
}

.badge-warning {
    background-color: #ffc107;
    color: black;
    font-weight: 700;
    float: none;
}

.candidatefeedback-form-group:after {
    content: " ";
    display: block;
    border: 1px solid #777777;
    margin-top: 15px;
}

.candidatefeedback-form-group .help-block {
    font-size: 10px;
}

/* For Org Chart */
.search-highlight {
    background-color: rgb(108 255 69 / 50%);
}

#chart-container {
    text-align: center;
}

#chart-container .content {
    margin-left: 0px;
}

.orgchart .greyout .title {
    background-color: #6c6e70 !important;
}

.orgchart .greyout .content {
    border-color: #6c6e70 !important;
}

.orgchart .node .title a {
    color: #ffffff !important;
}

.orgchart {
    background-image: unset;
    background: #fff !important;
}

.orgchart .title {
    height: auto !important;
    word-wrap: break-word;
    word-break: normal;
    white-space: normal !important;
    display: block;
}

.orgchart .content {
    height: auto !important;
    word-wrap: break-word;
    word-break: normal;
    white-space: normal !important;
    display: block;
}

.oc-export-btn {
    display: none;
}

.completed {
    color: rgb(12, 142, 28);
}

.greyfont {
    color: rgb(132, 132, 132);
}

.redfont {
    color: rgb(255, 0, 0);
}

.outlookicon {
    background-image: url(../../static/images/Outlook.svg);
}

//.ctmicon {
//    background-image: url(../../static/images/ctm_icon1.png);
//}

.concuricon {
    background-image: url(../../static/images/concur.png);
}

.btn-buyer {
    color: #302f31;
    background-color: #edc239;
    border-color: #c6a331;
}

.btn-buyer:hover {
    color: #aaaaaa;
}

.btn-seller {
    color: #ffffff;
    background-color: #9a2aed;
    border-color: #6000c6;
}

.btn-seller-no {
    color: #302f31;
    background-color: #c8c8c8;
    border-color: #949494;
}

.btn-seller:hover {
    color: #aaaaaa;
}

.btn-seller,
.btn-buyer,
.btn-seller-no {
    font-size: 17px;
}

.qriticon {
    background-image: url(../../static/images/qriticon.png);
}

.onedriveicon {
    background-image: url(../../static/images/OneDrive.svg);
}

.sharepointicon {
    background-image: url(../../static/images/SharePoint.svg);
}

/* Making Tabs look better */
.nav-tabs > li.active > a:not(.thin) {
    padding-bottom: 21px !important;
}

/* Make Wiki Tables look better */

#article-container table {
    width: 100%;
}

#article-container th {
    border-bottom: 2px solid #dddddd;
    border-right: 2px solid #dddddd;
    padding: 8px;
}

#article-container td {
    border-top: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    padding: 8px;
}

.scrolledTable {
    overflow-y: auto;
    clear: both;
}

.table-bordered > tbody > tr > td.active_pl {
    font-weight: bold;
    border-left: 3px solid #d7171f;
    border-right: 3px solid #d7171f;
    border-collapse: collapse;
}

.rnrimpact_table > tbody > tr > td {
    vertical-align: middle;
}

.pillar-btn {
    border: none;
    outline: none;
    padding: 10px 16px;
    background-color: white;
    cursor: pointer;
    font-size: 15px;
    border-radius: 6px;
}

.pillar-active,
.pillar-btn:hover {
    background-color: #a1a1a1;
    color: white;
}

/* Home page clocks */

#world_clocks {
    text-align: center;
    font-size: 12px;
}

#world_clocks td {
    padding: 2px;
}

#world_clocks .clock {
    font-family: "Courier";
    font-weight: bold;
}

/* Reporting Section */

#marginsummarytable tr.even td {
    font-style: italic;
}

.table-scrollX {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.card {
    border: 1px solid lightgrey;
    border-radius: 6px;
}

.card-body {
    padding: 10px;
}

.panel-top_border {
    border-top-width: 1px;
}

.bd-po-detail {
    padding: 2px;
    background-color: #f3f3f3;
}

#iqp_image,
.levers_image {
    width: 100%;
}

#iqp_panel {
    display: none;
}

.table_no_white-space tr td {
    white-space: nowrap;
}

.text-wrap {
    white-space: normal;
}

.width-300 {
    width: 300px;
}

.width-100 {
    width: 100px;
}

.h-100pc {
    height: 100%;
}

.icon_nav {
    margin: 0 0 10px;
}

.icon_drop_li {
    position: relative;
    display: inline-block;
    width: 32.29%;
    height: 65px;
    margin: 0px;
    background-color: #2f2f30;
}

.icon_nav_li:hover {
    background-color: #2f2f30;
}

.icon_nav_a {
    height: 100%;
    min-width: 100%;
    color: #b4b6bd;
    font-size: 12px;
    text-decoration: none !important;
    transition: all 0.4s;
    -moz-transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    text-align: center !important;
    position: relative;
    display: table-cell;
    vertical-align: middle;
}

.icon_nav > ul > li.active > div > a {
    color: white !important;
}

.icon_nav_a:focus {
    color: #b4b6bd;
}

.icon_drop_li.active {
    background-color: #232324 !important;
}

.alten-li.active > a {
    color: #fedc00 !important;
}

.icon_nav_ul {
    padding: 0px;
    margin: 0px;
}

.icon_nav_section_header {
    color: white;
    margin-top: 20px;
    font-size: 18px;
    margin-bottom: 10px;
}

.icon_nav_group_icon {
    position: relative;
    font-size: 25px;
    display: block;
}

.icon_nav_group_title {
    position: relative;
    display: block;
    margin-top: 5px;
    font-size: 12px;
    padding-bottom: 3px;
}

.icon_nav_group {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    float: left;
    height: 100%;
    text-align: center;
    display: table;
}

.nav_bar_line {
    height: 0px;
    width: 100%;
}

.comment_form {
    width: 100%;
}

.toggle_sow_na {
    color: #232324;
}

.toggle_sow_na:hover {
    color: #0d0d0e;
}

#zoomBop {
    padding: 5px 3px;
    background: #ffffff;
    color: #302f31;
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    text-align: center;
    border: 1px solid #e7e7e7;
}

#zoomBop:hover {
    cursor: pointer;
}

.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
    border: 1px solid #e7e7e7;
    height: auto;
    min-height: 38px;
}

.bootstrap_tabular_fix .select2 {
    padding: 0;
    height: 100%;
}

.title-p {
    margin-top: 10px;
}

.right {
    float: right;
}

.dashboard {
    text-align: center;
}

.pro-dash {
    transform: translateY(-45px);
}

.bg-dash {
    font-size: 50px;
}

.point {
    cursor: pointer;
}

.right-pad {
    padding-right: 5px;
}

.not-red {
    color: #000;
    text-decoration: none !important;
    padding-right: 10px;
}

.no-underline-cls {
    text-decoration: none !important;
}

#ambition_statement {
    font-size: large;
    colour: #000;
}

.mini-ambition {
    margin-top: 8px;
    margin-bottom: 10px;
    margin-right: 8px;
    color: grey;
    font-size: large;
}

.dev-group {
    margin-bottom: 50px;
}

.mb-20 {
    margin-bottom: 20px;
}

.not_underline:hover,
.not_underline:active,
.not_underline:focus {
    text-decoration: none !important;
}

.white {
    background-color: white;
}

.btn-pink {
    color: #fff;
    background-color: #ff3bb4;
    border-color: #b3257d;
}

.btn-pink:hover {
    color: #fff;
}

.performance-label-line {
    position: absolute;
    background: #000;
    height: 20px;
    width: 1px;
    right: 50%;
}

.performance-label-value {
    position: relative;
    margin-top: -20px;
    text-align: center;
    font-size: 12px;
}

.pro-img {
    float: right;
    height: 35px;
    width: 35px;
}

.add-pro {
    float: right;
    margin-bottom: 10px;
}

#datatable tbody tr.datatable-total-row td {
    border-color: rgb(171, 171, 171);
    border-width: 2px 0;
    font-weight: bold;
}

/* Cookie Consent */

/* popup */
.cc-window {
    background: #2f2f30;
    color: #fff;
}

.cc-link {
    color: #fff;
}

/* button */
.cc-btn {
    background: transparent;
    border: 1px solid #d7171f;
    color: #d7171f;
}

/* End Cookie Consent */
#teleport + span .select2-selection__arrow {
    display: none;
}

#select2-teleport-results li .select2-results__option--highlighted {
    background-color: #d7171f;
    opacity: 80%;
    border-radius: 4px;
}

#select2-teleport-container {
    padding-top: 4px;
}

.right-btn-container {
    float: right;
    margin-bottom: 20px;
}

.alert-opp {
    background-color: rgba(159, 171, 188, 0.2);
}

.alert-spread {
    background-color: rgba(220, 220, 220, 0.2);
}

.select2-selection__rendered {
    padding-top: 4px;
}

.invalid {
    border-color: red !important;
}

.coming-soon {
    height: 200px;
}

.sortable {
    overflow-y: auto;
}

.target-left-grey {
    background-color: #ebebeb;
    color: #7d7d7d;
    height: 70px;
}

.left-margin {
    margin-left: 10px;
}

.right-margin {
    margin-right: 15px;
}

.disable-grey {
    color: #7d7d7d;
    text-decoration: none !important;
}

.orange {
    background-color: #ed9e00 !important;
}

.complete-action {
    background-color: #ebebeb;
}

.action-icon {
    margin-right: 15px;
    color: #7d7d7d;
}

.grab {
    cursor: grab;
}

.target-header {
    color: #000;
    height: 70px;
}

.target-body {
    border-top: 1px solid #dddddd;
    margin-top: 0px !important;
}

.target-footer {
    background-color: #ebebeb;
    color: #7d7d7d;
    height: 70px;
}

.grey {
    background-color: #6c757d !important;
}

.mini-pers-body {
    padding-bottom: 5px !important;
    padding-top: 10px !important;
}

.mini-pers-name-group {
    margin-top: 10px;
    margin-bottom: 10px;
}

.mini-pers-name {
    display: inline !important;
}

.mini-pers-span {
    padding-left: 10px;
}

.mini-face {
    margin-top: 3px;
    margin-bottom: 3px;
}

.dev-home li {
    width: calc(95% / 8);
}

#use_questions_modal label {
    padding-left: 0px !important;
}

.performance {
    padding-top: 10px !important;
}

.performance-progress {
    height: 20px;
    margin-left: 0%;
}

.rejected-feedback {
    text-decoration: line-through;
}

.thin {
    padding: 5px 10px !important;
}

.dev_choice {
    background: #f2f2f2;
    border-radius: 4px;
    border: none;
    box-shadow: 0 -2px 0 -1px rgba(0, 0, 0, 0.12) inset;
    padding: 5px;
}

.dev_loading {
    text-align: center;
    margin: 40px;
}

#dev-filters span,
select {
    margin-right: 10px;
}

#reportrange {
    background: #fff;
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #ccc;
}

#reportrange span {
    margin-left: 10px;
    margin-right: 10px;
}

.details-pers {
    font-size: small;
    margin-top: 5px;
}

.spacey-xs {
    margin: 2px;
}

.spacey-s {
    margin: 5px;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 4;
    background-color: #f5f5f5;
    margin-right: -2px;
    margin-left: -2px;
}

.closed-targets {
    background-color: #f2f2f2 !important;
}

.no-left-pad {
    padding-left: 0px !important;
    margin-bottom: 5px;
}

.full-width {
    width: 100% !important;
}

.side-bar-grey {
    border-bottom-width: 0px !important;
    box-shadow: inset 5px 0 0 0 #b4b6bd;
}

.side-bar-red {
    border-bottom-width: 0px !important;
    box-shadow: inset 5px 0 0 0 #d7171f;
}

.side-bar-blue {
    border-bottom-width: 0px !important;
    box-shadow: inset 5px 0 0 0 #158cba;
}

.side-bar-mh {
    border-bottom-width: 0px !important;
    box-shadow: inset 5px 0 0 0 #009432;
}

.mh_green {
    color: #009432;
}

.index-submenu-padding {
    padding-left: 10px;
    margin-top: 30px !important;
    margin-bottom: 20px;
}

.letter_category {
    border: solid 1px #dcdcdc;
    padding: 15px;
    margin-top: -1px;
    margin-left: -16px;
}

.message {
    text-align: center;
    align-self: center;
    max-width: 800px;
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
}

.error_content {
    margin: 30vh 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error_h1 {
    font-size: 80px;
    margin: 20px;
    font-weight: 400;
    line-height: 1.1;
    color: #302f31;
}

.rag_green {
    background-color: #44bb66;
}

.rag_red {
    background-color: #cc4433;
}

.rag_grey {
    background-color: #8f8f8f;
}

.rag_amber {
    background-color: #f9a500;
}

.levers_image {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

.levers_image:hover {
    opacity: 0.7;
}

#levers_modal .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #ffffff;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

#levers_modal .close:hover,
#levers_modal .close:focus {
    color: #bbb;
    cursor: pointer;
}

#levers_image_modal {
    margin: auto;
    display: block;
    width: 70%;
}

.account {
    box-shadow: 0 2px 4px 0 hsla(0, 0%, 0%, 0.1);
    border-left: solid 5px #3d4552 !important;
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 20px;
    display: flow-root;
}

.account:nth-of-type(odd) {
    border-left: solid 5px #647fac !important;
}

.account_header {
    display: flow-root;
}

.fop {
    box-shadow: 0 2px 4px 0 hsla(0, 0%, 0%, 0.1);
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 10px;
}

.inactive_fop {
    background-color: #eeeeee;
}

.account_header:hover {
    cursor: pointer;
}

.account_nav {
    width: fit-content;
    background-color: #fafafa;
}

.account_top_header {
    display: flex;
}

.total_row {
    background-color: rgba(159, 171, 188, 0.2) !important;
}

.badge-success {
    background-color: #8db858;
}

.tooltip-inner {
    max-width: 500px !important;
}

table caption {
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    font-family: DolceVitaLight, sans-serif;
    line-height: 1.1;
    color: #302f31;
}

/* This helper class seems to be missing from the main style.css */
.p-r-0 {
    padding-right: 0;
}

.form-control {
    box-shadow: none;
}

.datepicker {
    border-color: #e7e7e7;
}

.wiki-modal .modal-dialog {
    width: 90vw;
    height: 90vh;
}

.wiki-modal iframe[name="previewWindow"] {
    height: 80vh;
    width: 85vw;
}

.alert-tncs {
    background-color: #dadbe7;
    border-color: #c1c3d7;
}

.modal-backdrop {
    z-index: 100000 !important;
}

.modal {
    z-index: 100001 !important;
}

.select2-container--open {
    z-index: 9999999 !important;
}

.modal-backdrop {
    z-index: 100000 !important;
}

.modal {
    z-index: 100001 !important;
}

.select2-container--open {
    z-index: 9999999 !important;
}

.select2-container {
    width: 100% !important;
}

/*ACCOUNT PLANS*/

#rel_map_table_wrapper {
    width: 900px;
    position: relative;
    overflow: auto;
}

#rel_map_table {
    font-weight: bold;
    margin: 0;
    padding: 0;
    border: 0;
    width: 900px;
    height: 540px;
    table-layout: fixed;
}

#rel_map_table td {
    vertical-align: middle;
    height: 60px;
    width: 100px;
    border: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.mapped_contact {
    font-weight: bold;
    height: 25px;
    width: 25px;
    text-align: center;
    line-height: 25px;
    background-color: #ffffff;
    border-radius: 50%;
    display: inline-block;
    cursor: grab;
    border: #000000 solid 1px;
}

.height_of_select {
    line-height: 38px;
}

#plan_accordion textarea {
    height: 84px;
}

#plan_accordion #next_steps textarea {
    // The default height of the bootstrap textarea
    height: 38px;
}

#plan_accordion .form-group,
#plan_accordion td,
#plan_accordion th {
    margin: 0;
    padding: 0;
}

#plan_accordion th {
    vertical-align: middle;
}

#id_client_intel-solutions_and_markets {
    height: 156px !important;
}

#id_client_intel-market_trends {
    height: 126px !important;
}

.ui-sortable-helper {
    display: table;
}

.section_header {
    background-color: #eee;
    padding: 1px;
    margin-bottom: 10px;
    text-align: center;
}

.no-hover:hover {
    cursor: not-allowed;
}

.w-50 {
    width: 50%;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-wrap: wrap;
}

.d-none {
    display: none;
}

.notification-badge {
    font-weight: 700;
}

.badge-bottom-right {
    margin-top: 20px;
}

.badge-bottom-left {
    margin-top: 20px;
    margin-right: 48px;
}

.badge-bottom-left {
    margin-right: 48px;
}

.hub-htmx-indicator {
    height: 0;
}

.htmx-request.hub-htmx-indicator {
    height: 60px;
}

.download-container,
.download {
    line-height: 1.5;
}

.progress-bar.bg-success {
    background-color: #28a745 !important;
}

.progress-bar.bg-danger {
    background-color: #d7171f !important;
}

.progress-bar-striped {
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    background-size: 1rem 1rem;
}

.progress {
    border: none;
    margin-bottom: 0;
}

.download-name:hover {
    font-weight: 500;
}

#async-downloads-icon {
    font-size: x-large;
    vertical-align: middle;
}

#downloads-dropdown .dropdown-item {
    padding: 0.25rem 1.5rem;
    color: #212529;
}

.btn-star {
    border: 0;
    background: none;
    color: #edc239;
    vertical-align: middle;
}

/* FM Reporting */

.drilldown {
    cursor: pointer;
}

/*Business Plans*/

.priority-row,
.issue-row,
.goal-row {
    padding-left: 8px;
    border-left: 3px solid #158cba;
}

/* Resourcing */
.border-assignment-group {
    border: 1px solid;
    border-radius: 5px;
    padding: 5px;
}

/* Qwiki */
.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.bg-last-oto {
    background-color: #778899;
}

.target-panel {
    border: 0;
    margin-bottom: 15px;
}

.border-playbook-target {
    border-left: solid 5px #da667b !important;
    border-radius: 5px;
}

.badge-playbook {
    background-color: #da667b;
}

.border-template-target {
    border-left: solid 5px #980101 !important;
    border-radius: 5px;
}

.badge-template {
    background-color: #980101;
}

.border-custom-target {
    border-left: solid 5px #78a1bb !important;
    border-radius: 5px;
}

.badge-custom {
    background-color: #78a1bb;
}

.btn-dv-red {
    background-color: #980101;
    color: white;
}

.btn-dv-red:hover {
    color: white;
}

.overflow-auto {
    overflow: auto;
}

.dom-table-left {
    float: left;
}

.modal-fc {
    width: fit-content;
}

#iqp_image:hover {
    cursor: pointer;
}

#personal-bd-contents .has-success input,
#personal-bd-contents .has-success select {
    border-color: #e7e7e7 !important;
    box-shadow: none !important;
}

#personal-bd-contents .has-success label {
    color: #232324 !important;
}

.person {
    width: 200px;
    display: inline-block;
}

.alten-logo {
    max-width: 150px;
    width: 100%;
}

.d-inline-style {
    display: inline-block;
}

#scoring-guidance-top-row {
    background-image: linear-gradient(to right, #5a8ac1, #ffffff, #f8696b);
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.d-inline-block {
    display: inline-block;
}

.nowrap {
    white-space: nowrap;
}

span[name="delete"] a {
    text-decoration: none !important;
}

.swal-modal .swal-footer,
.swal-modal .swal-text {
    text-align: center;
}

.card-disabled {
    background-color: #e0e0e0;
}

.mr-1 {
    margin-right: 0.3rem;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
}

.nav-item-right {
    position: absolute !important;
    right: 20px;
    top: 15px;
}

.label-accrued,
.btn-accrued {
    color: #ffffff;
    background-color: #bf2c2c;
    border-color: #9b2c2c;
}
