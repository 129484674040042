body.dark {
    background-color: #262627 !important;
    color: #ffffff !important;
}

body.dark .panel {
    background-color: #373738 !important;
    border-color: #2e2e2f;
}

body.dark .widget-panel,
body.dark .media-body,
body.dark .dynamic_select,
body.dark select,
body.dark .list-group-item,
body.dark .nav-header,
body.dark .nav,
body.dark .tab-content,
body.dark .assettab,
body.dark .table > tbody > tr.active > td,
body.dark .pillar-btn,
body.dark .top-menu {
    background-color: #373738 !important;
}

body.dark .panel-heading,
body.dark header,
body.dark footer,
body.dark .form-control,
body.dark textarea,
body.dark input {
    background-color: #373738 !important;
    color: #ffffff !important;
}

body.dark .nav.nav-tabs > li.active > a {
    background-color: #373738 !important;
    color: #ffffff !important;
}

body.dark .nav.nav-tabs > li > a {
    background-color: #4e5052 !important;
    color: #ffffff;
}

body.dark .input-group-addon {
    background-color: #373738;
}

body.dark table.dataTable tbody tr:hover,
body.dark .table-clickable tbody tr:hover,
body.dark .table-striped > tbody > tr:nth-of-type(odd),
body.dark .timeline-panel,
body.dark .table-hover > tbody > tr:hover {
    background-color: #232324;
}

body.dark .h1,
body.dark .h2,
body.dark .h3,
body.dark .h4,
body.dark .h5,
body.dark .h6,
body.dark h1,
body.dark h2,
body.dark h3,
body.dark h4,
body.dark h5,
body.dark h6,
body.dark .vis-text,
body.dark .vis-inner {
    color: #ffffff !important;
}

body.dark .bdtarget,
body.dark .bdtotal,
body.dark .bdthisweek,
body.dark .datepicker,
body.dark .table > tbody > tr > .active {
    color: #232324;
}

body.dark .modal-content {
    background-color: #373738 !important;
    color: #ffffff;
}

body.dark .top-menu li > a:hover,
body.dark .top-menu li > a:focus {
    background-color: #4e5052;
    color: #ffffff;
}

body.dark .dropdown-menu {
    background-color: #373738;
}

body.dark .dropdown .extended li {
    border-color: #232324;
}

body.dark #week_list {
    background-color: #6c6e70 !important;
}

body.dark #timesheet .table-calendar td.non-zero-val {
    font-weight: bold;
    color: #e4e6e8 !important;
}

body.dark #modal-activity select {
    color: #a4a6a8 !important;
}

body.dark .orgchart {
    background-color: #373738;
}

body.dark .select2-dropdown,
body.dark .select2-selection,
body.dark .select2-selection--multiple {
    background: #232324 !important;
    color: #ffffff;
}

body.dark #expenses .expensesby .name {
    color: #ffffff;
}

body.dark #expensetable table {
    background: #373738 !important;
    color: #ffffff;
}

body.dark #expenses .button-action .bDark {
    background-color: #61616a;
}

body.dark #expenses .button-action .bGray {
    background-color: #6d6e71;
}

body.dark #expenses .claimTitle {
    background-color: #888888;
    border-bottom-color: #888888;
}

body.dark #expenses .claimTitle.active {
    background-color: #787878;
}

body.dark #expenses .reciept {
    background-color: #888888;
}

body.dark #expenses .reciept .form {
    background-color: #888888;
    border-color: #666666;
}

body.dark #expenses .reciept .title {
    color: #ffffff;
}

body.dark #expenses .reciept .message {
    color: #eeeeee;
}

body.dark #expenses .modeoptions:hover {
    background-color: #777777 !important;
}

body.dark #expenses #jq-dropdown > ul {
    background-color: #888888;
}

body.dark #timesheet p.days-left,
body.dark #timesheet p.hours-spent {
    color: #ffffff;
}

body.dark #timesheet .btn-dark,
body.dark .btn-dark {
    background-color: #61616a;
}

body.dark #timesheet .btn-white {
    background-color: #aaaaaa;
}

body.dark #timesheet .table-calendar tbody tr.activity:hover {
    background-color: #262627;
}

body.dark #timesheet td.orange-bk {
    background-color: #e7840e;
}

body.dark .selector .selector-available h2 {
    background-color: #373738;
}

body.dark .spreqstable th,
body.dark .opsreqstable th {
    color: #eeeeee;
    background-color: #666666;
}

body.dark table caption {
    color: #ffffff;
    background-color: #616166;
}

body.dark .thumbnail {
    background-color: #616166;
}

body.dark #ticket-description {
    background-color: #877750;
}

body.dark .followup.well,
body.dark .well,
body.dark .row_tablehead {
    background-color: #454546;
}

body.dark table.DTFC_Cloned tr {
    background-color: #373738;
}

/* Boostrap Switch */

body.dark .bootstrap-switch .bootstrap-switch-label {
    background-color: #aaaaaa;
}
body.dark
    .bootstrap-switch
    .bootstrap-switch-handle-off.bootstrap-switch-default {
    color: #ffffff;
    background-color: #888888;
}
body.dark
    .bootstrap-switch
    .bootstrap-switch-handle-on.bootstrap-switch-primary {
    color: #ffffff;
    background-color: #2386c7;
}

body.dark .breadcrumb {
    background-color: #aaaaaa;
    border-color: #999999;
}
body.dark .breadcrumb > .active,
body.dark .breadcrumb > li + li:before {
    color: #eeeeee;
}

body.dark .nav-pills > li.active > a {
    background-color: #2386c7;
}
body.dark .nav-pills > li:not(.active) > a:hover {
    background-color: #61616a;
}
body.dark .article-list li > a:hover,
body.dark .toc li > a:hover {
    background-color: #61616a;
}

/* Buttons */

body.dark .alert-default,
body.dark .btn-default,
body.dark .pagination > .disabled > a,
body.dark .pagination > li > a {
    color: #eeeeee;
    background-color: #6d6e71 !important;
    border-color: #61616a !important;
}
body.dark .btn-default:active,
body.dark .btn-default.active,
body.dark .pagination > .disabled > a:active {
    color: #eeeeee;
    background-color: #61616a !important;
}

body.dark .alert-info,
body.dark .btn-info {
    color: #eeeeee;
    background-color: #04b4b4 !important;
    border-color: #039595 !important;
}
body.dark .btn-info:active,
body.dark .btn-info.active {
    color: #eeeeee;
    background-color: #039595 !important;
}

body.dark .alert-primary,
body.dark .btn-primary,
body.dark .pagination > .active > a {
    color: #eeeeee;
    background-color: #2386c7 !important;
    border-color: #1e71a9 !important;
}
body.dark .btn-primary:active,
body.dark .btn-primary.active,
body.dark .pagination > .active > a:active {
    color: #eeeeee;
    background-color: #1e71a9 !important;
}

body.dark .alert-warning,
body.dark .btn-warning {
    color: #eeeeee;
    background-color: #e37c00 !important;
    border-color: #aa5d00 !important;
}
body.dark .btn-warning:active,
body.dark .btn-warning.active {
    color: #eeeeee;
    background-color: #aa5d00 !important;
}

body.dark .alert-danger,
body.dark .btn-danger {
    color: #eeeeee;
    background-color: #c6131d !important;
    border-color: #9a0f16 !important;
}
body.dark .btn-danger:active,
body.dark .btn-danger.active {
    color: #eeeeee;
    background-color: #9a0f16 !important;
}

body.dark .alert-success,
body.dark .btn-success {
    color: #eeeeee;
    background-color: #8fa935 !important;
    border-color: #72872a !important;
}
body.dark .btn-success:active,
body.dark .btn-success.active {
    color: #eeeeee;
    background-color: #72872a !important;
}

body.dark .alert-new,
body.dark .btn-new {
    color: #302f31;
    background-color: #edc239 !important;
    border-color: #c6a331 !important;
}
body.dark .btn-new:active,
body.dark .btn-new.active {
    color: #302f31;
    background-color: #c6a331 !important;
}

body.dark #zoomBop {
    color: #eeeeee;
    background: #302f31;
}

body.dark .widget-panel i {
    color: #eeeeee;
}

aside.left-panel.alten-aside.dark {
    border-right: 5px solid #c0c0c8;
    background-color: #484848;
}
