/* Animation / Shadow / Radius */

.log-in-detail a,
.icon-list div:hover,
.widget-style-1 i,
.profile-widget,
.portlet,
.ionicon-list i {
    -webkit-transition: all 220ms ease-in-out;
    -moz-transition: all 220ms ease-in-out;
    -o-transition: all 220ms ease-in-out;
    transition: all 220ms ease-in-out;
}
.bx-s,
.panel,
.portlet,
.nav.nav-tabs + .tab-content,
.tabs-vertical-env .tab-content,
.widget-panel,
.profile-widget,
.profile-widget img,
.tiles,
.tile-stats,
.mini-stat,
header {
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}
.br-radius,
.tiles,
.tile-stats,
.portlet {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
/* Padding - Margin */

.p-0 {
    padding: 0px !important;
}
.p-t-0 {
    padding-top: 0px !important;
}
.p-t-10 {
    padding-top: 10px !important;
}
.p-b-10 {
    padding-bottom: 10px !important;
}
.p-r-10 {
    padding-right: 10px !important;
}

.m-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.m-0 {
    margin: 0px !important;
}
.m-r-5 {
    margin-right: 5px;
}
.m-r-10 {
    margin-right: 10px;
}
.m-r-15 {
    margin-right: 15px;
}
.m-l-5 {
    margin-left: 5px;
}

.m-l-10 {
    margin-left: 10px;
}
.m-l-15 {
    margin-left: 15px;
}
.m-t-5 {
    margin-top: 5px !important;
}
.m-t-0 {
    margin-top: 0px;
}
.m-t-10 {
    margin-top: 10px !important;
}
.m-t-15 {
    margin-top: 15px;
}
.m-t-20 {
    margin-top: 20px;
}
.m-t-30 {
    margin-top: 30px !important;
}
.m-t-40 {
    margin-top: 40px !important;
}
.m-b-0 {
    margin-bottom: 0px;
}
.m-b-5 {
    margin-bottom: 5px;
}
.m-b-10 {
    margin-bottom: 10px;
}
.m-b-15 {
    margin-bottom: 15px;
}
.m-b-30 {
    margin-bottom: 30px;
}
/* ---- Width-Sizes ---*/

.w-xs {
    min-width: 80px;
}
.w-sm {
    min-width: 95px;
}
.w-md {
    min-width: 110px;
}
.w-lg {
    min-width: 140px;
}

.w-100 {
    width: 100% !important;
}
/* ---- Images-Sizes ---*/

.thumb-sm {
    height: 32px;
    width: 32px;
}
.thumb-sm img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}
.thumb-xs {
    height: 24px;
    width: 24px;
}
.thumb-md {
    width: 64px;
    height: 64px;
}
.thumb-lg {
    height: 84px;
    width: 84px;
}
/* Text-sizes */

.text-xs {
    font-size: 12px;
}
.text-sm {
    font-size: 16px;
}
.text-md {
    font-size: 20px;
}
.text-lg {
    font-size: 24px !important;
}
/* Extra */

.m-h-50 {
    min-height: 50px;
}
.l-h-34 {
    line-height: 34px;
}
.font-light {
    font-weight: 300;
}
.wrapper-md {
    padding: 20px;
}
.pull-in {
    margin-left: -15px;
    margin-right: -15px;
}
.b-0 {
    border: none !important;
}
/* Dropcap */

.dropcap {
    font-size: 3.1em;
}
.dropcap,
.dropcap-circle,
.dropcap-square {
    display: block;
    float: left;
    font-weight: 400;
    line-height: 36px;
    margin-right: 6px;
    text-shadow: none;
}

/* Custom Radio/Checkbox */
.cr-styled {
    display: inline-block;
    margin: 0px 2px;
    line-height: 20px;
    font-weight: normal;
    cursor: pointer;
}
.cr-styled i {
    display: inline-block;
    height: 18px;
    width: 18px;
    cursor: pointer;
    vertical-align: middle;
    border: 2px solid #ccc;
    border-radius: 3px;
    text-align: center;
    padding-top: 1px;
    font-family: "FontAwesome";
    margin-top: -4px;
    margin-right: 3px;
    font-size: 12px;
}
.cr-styled input {
    visibility: hidden;
    display: none;
}
.cr-styled input[type="checkbox"]:checked + i:before {
    content: "\f00c";
}
.cr-styled input[type="radio"] + i {
    border-radius: 18px;
    font-size: 11px;
    line-height: 13px;
}

.cr-styled input[type="radio"]:checked + i:before {
    content: "\f111";
}
.cr-styled input:checked + i {
    border-color: #6e8cd7;
    color: #6e8cd7;
}
/* Icon-list (Used Icon-page only) */

.icon-list div {
    line-height: 40px;
    white-space: nowrap;
    cursor: pointer;
}
.icon-list i {
    display: inline-block;
    width: 40px;
    margin: 0;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
    -webkit-transition: font-size 0.2s;
    transition: font-size 0.2s;
}
.ionicon-list i {
    font-size: 16px;
}
.ionicon-list .col-md-3:hover i,
.icon-list .col-md-3:hover i {
    moz-transform: scale(2);
    -webkit-transform: scale(2);
    -o-transform: scale(2);
    transform: scale(2);
}
/* Grid-structure (Used Grid-page only) */

.grid-structure .grid-container {
    background-color: #e6eaed;
    padding: 10px 20px;
    margin-bottom: 10px;
}
/* Custom Choose-button */

.fileUpload {
    position: relative;
    overflow: hidden;
}
.fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

/* Only Mozila */
@-moz-document url-prefix() {
    .cr-styled i {
        padding-top: 0px;
    }
    label {
        font-weight: 600;
    }
}

flex-inline {
    display: inline-flex;
}
